<template>
    <div class="transfer">
        <h5header :name="$t('manage.desc58')" :right="false" />
        <div class="transfer-con">
            <div class="transfer-tbg flex">
                <div @click="$router.go(-1)">{{ $t('new.desc3') }}</div>
                <div class="flex"><img src="../../public/images/new/righticon2.png" alt="" /> {{ $t('manage.desc58') }}
                </div>
            </div>
            <div class="transfer-wrappar flex">
                <div class="wrappar-left">
                    <div class="left-name">{{ $t('manage.desc58') }}</div>
                    <div class="planet flex">
                        <div class="img1" v-if="planetInfo.icon != undefined">
                            <star-img :sphereImg="planetInfo.icon" :starWidth="120" :starHeight="120" />
                        </div>
                        <div class="planet-info flex">
                            <div class="info">
                                <div>{{ $t('manage.desc21') }}</div>
                                <p class="ellipse">{{ planetInfo.planetName }}</p>
                            </div>
                            <div class="info">
                                <div>{{ $t('node.desc20') }}</div>
                                <p>L{{ planetInfo.levelId }} {{ planetInfo.levelName }}</p>
                            </div>
                            <div class="info">
                                <div>{{ $t('node.desc21') }}</div>
                                <p>{{ planetInfo.price }} {{ planetInfo.investCoinName }}</p>
                            </div>
                            <div class="info">
                                <div>{{ $t('node.desc19') }}</div>
                                <p>{{ planetInfo.track }} - {{ planetInfo.no }}</p>
                            </div>
                            <div class="info">
                                <div>{{ $t('manage.desc59') }}</div>
                                <p>{{ planetInfo.sellCount }}/{{ planetInfo.landCount }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="transfer-list">
                        <div class="list-title">
                            {{ $t('manage.desc60') }} <span>*</span>
                        </div>
                        <div class="list-input">
                            <input type="tel" v-model="targetUserId" :placeholder="$t('manage.desc61')" maxlength="19"
                                onkeyup="this.value=this.value.replace(/\D/g,'')"
                                onafterpaste="this.value=this.value.replace(/\D/g,'')" />
                        </div>
                    </div>
                    <div class="transfer-list" v-if="planetInfo.levelId != 1">
                        <div class="list-title flex">
                            {{ $t('manage.desc62') }}
                            <el-tooltip class="box-item" raw-content effect="dark" placement="bottom">
                                <img src="../../public/images/new/tipsicon.png" alt="" />
                                <template #content>
                                    <div style="max-width:200px ;"> {{ $t('manage.desc67') }}</div>
                                </template>
                            </el-tooltip>
                        </div>
                        <div class="list-input">
                            <input type="text" v-model="recommendCode" :placeholder="$t('manage.desc63')" />
                        </div>
                    </div>
                    <div class="transfer-tips flex">
                        <div class="tips-name flex">
                            {{ $t('manage.desc64') }} <el-tooltip class="box-item" raw-content effect="dark"
                                placement="bottom">
                                <img src="../../public/images/new/tipsicon.png" alt="" />
                                <template #content>
                                    <div style="max-width:200px ;"> {{ $t('manage.desc78', {
                                        n1: feeInfo.amountPersent * 100
                                    }) }}</div>
                                </template>
                            </el-tooltip>
                        </div>
                        <div class="tips-info">{{ common.cutXiaoNum1(feeInfo.amount) || 0 }} DA0T</div>
                    </div>
                    <div class="transfer-tips flex">
                        <div class="tips-name flex">
                            {{ $t('manage.desc65') }}
                        </div>
                        <div class="tips-info">{{ common.cutXiaoNum1(balance) }}</div>
                    </div>
                    <div class="transfer-check flex" @click="handlerCheck">
                        <img :src="check ? require('../../public/images/new/checkimg.png') : require('../../public/images/new/checkimg1.png')"
                            alt="" />
                        <div v-html="$t('manage.desc66')"></div>
                    </div>
                    <div class="btn-all flex">
                        <div class="flexcenter" @click="$router.go(-1)">{{ $t('home.desc45') }}</div>
                        <div class="flexcenter" v-loading="loading" @click="handlerShow">{{ $t('home.desc46') }}</div>
                    </div>
                </div>
                <div class="wrappar-rules">
                    <div class="rules-title">{{ $t('manage.desc68') }}</div>
                    <div class="rules-list">
                        <div>{{ $t('manage.desc69') }}</div>
                        <div>{{ $t('manage.desc70') }}</div>
                        <div>{{ $t('manage.desc71') }}</div>
                        <div>{{ $t('manage.desc72', {
                            n1: common.cutXiaoNum1(feeInfo.indexAmount), n2: feeInfo.amountPersent *
                                100
                        }) }}</div>
                        <div>{{ $t('manage.desc73') }}</div>
                        <div>{{ $t('manage.desc74') }}</div>
                        <div>{{ $t('manage.desc75') }}</div>
                        <div>{{ $t('manage.desc76') }}</div>
                        <div>{{ $t('manage.desc761') }}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 交易密码 -->
        <el-dialog v-model="showpsd" :append-to-body="true">
            <div class="modal-con">
                <img src="../../public/images/new/closeicon.png" alt="" class="close" @click="showpsd = false" />
                <div class="modal-title">{{ $t("home.desc7") }}</div>
                <div class="modal-desc">{{ $t("node.desc53") }}</div>
                <div class="modal-input">
                    <psdinput @finish="finish" @finish1="finish1" ref="PsdInput" v-if="showpsd" />
                </div>
                <!-- <div class="modal-tips" v-if="(userInfo.hasUpdateTranPwd && userInfo.sourceType == 1) ||
                    (userInfo.hasUpdateTranPwd && userInfo.sourceType == 2)
                    ">
                    {{ $t("node.desc75") }}
                </div> -->
                <div class="modal-btncon flexcenter" @click="handlerPay" v-loading="loading">
                    {{ $t("home.desc20") }}
                </div>
                <div class="forgottitle" @click="handlerLogpsd">{{ $t('sign.desc72') }}</div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import h5header from "@/componets/h5header.vue";
import starImg from "@/componets/starImg.vue";
import psdinput from '@/componets/psdinput.vue'
import bus from "@/utils/bus";
export default {
    components: {
        starImg,
        psdinput,
        h5header
    },
    data() {
        return {
            loading: false,
            showpsd: false,
            planetInfo: {},
            feeInfo: {},
            balance: 0,
            check: true,
            targetUserId: '',
            recommendCode: '',
            userInfo: {},
            transactionPassword: ''
        }
    },
    mounted() {
        let info = JSON.parse(localStorage.getItem('planetInfo'))
        this.$post(this.URL.member.info, {}).then((res) => {
            if (res.code == 0) {
                this.userInfo = res.data;
            }
        });
        this.$post(this.URL.node.planet, {
            id: this.$route.query.planetId
        }).then(res => {
            if (res.code == 0) {
                this.planetInfo = {
                    planetName: info.planetName,
                    levelId: info.levelId,
                    icon: info.icon,
                    levelName: info.levelName,
                    landCount: res.data.landCount,
                    sellCount: info.sellLandCount,
                    investCoinName: res.data.investCoinName,
                    price: res.data.price,
                    track: info.track,
                    no: info.no,
                    investCoinId: res.data.investCoinId
                };
                this.getBalance()
            }
        })
        this.$post(this.URL.manage.fee, {
            planetId: this.$route.query.planetId
        }).then(res => {
            if (res.code == 0) {
                this.feeInfo = res.data;
            }
        })

    },
    methods: {
        handlerLogpsd() {
            this.showpsd = false;
            if (localStorage.getItem('ismobile') == 1) {
                this.$router.push('/user/info?id=2')
            } else {
                bus.emit("hadleropen", 1);
            }
        },
        handlerPay() {
            if (this.transactionPassword == "") {
                this.$message.error(this.$t("planet.desc17"));
                return;
            }
            if (!this.loading) {
                this.loading = true;
                this.$post(this.URL.manage.transfer, {
                    account: this.userInfo.account,
                    opType: 1,
                    transactionPassword: this.userInfo.sourceType == 2 || this.userInfo.sourceType == 3
                        ? this.transactionPassword
                        : this.common.mdpassword(this.transactionPassword),
                    planetId: this.$route.query.planetId,
                    targetUserId: this.targetUserId,
                    recommendCode: this.recommendCode
                }).then(res => {

                    if (res.code == 0) {
                        this.$message.success(this.$t('manage.desc77'));
                        setTimeout(() => {
                            this.$router.go(-1);
                        }, 1000);
                    } else {
                        this.loading = false;
                        this.transactionPassword = "";
                        this.$refs.PsdInput.reset();
                        this.$message.error(res.message)
                    }
                })
            }
        },
        finish(val) {
            this.transactionPassword = val;
        },
        finish1(val) {
            if (val == 0) {
                this.transactionPassword = "";
            }
        },
        handlerShow() {
            if (this.targetUserId == '') {
                this.$message.error(this.$t('manage.desc61'))
                return;
            }
            if (!this.loading) {
                this.loading = true;
                this.$post(this.URL.manage.isaccount, {
                    userId: this.targetUserId
                }).then(res => {
                    this.loading = false;
                    if (res.code == 0) {
                        this.transactionPassword = '';
                        this.showpsd = true;
                    } else {
                        this.$message.error(res.message)
                    }
                })

            }
        },
        getBalance() {
            this.$post(this.URL.assets.view, {
                relationCoinId: this.planetInfo.investCoinId,
                accountType: 1
            }).then(res => {
                if (res.code == 0) {
                    this.balance = res.data.balance
                }
            })
        },
        handlerCheck() {
            this.check ? this.check = false : this.check = true;
        }
    }
}
</script>

<style lang="less" scoped>
.transfer {
    width: 100%;
    padding: 136px 0 80px;
    background: url("../../public/images/new/landbg.png") center no-repeat;
    background-size: 100% 100%;

    .transfer-con {
        width: 1240px;
        margin: 0 auto;

        .transfer-tbg {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.6);
            line-height: 14px;

            div {
                &:first-child {
                    cursor: pointer;
                }

                &:last-child {
                    color: #fff;

                    img {
                        width: 12px;
                        height: 14px;
                        margin: 0 8px;
                    }
                }
            }
        }

        .transfer-wrappar {
            margin-top: 20px;
            padding: 32px 40px 40px;
            border-radius: 12px;
            background: #181818;
            justify-content: space-between;

            .left-name {
                margin-bottom: 32px;
                font-size: 24px;
                color: #FFFFFF;
            }

            .wrappar-left {
                flex: 0 0 516px;

                .planet {
                    padding-bottom: 32px;

                    .img1 {
                        flex: 0 0 120px;
                    }

                    .planet-info {
                        flex: 1;
                        margin-left: 32px;
                        flex-wrap: wrap;

                        .info {
                            flex: 0 0 33.3%;
                            width: 33.3%;
                            font-size: 14px;
                            color: rgba(255, 255, 255, 0.6);
                            line-height: 14px;

                            &:nth-child(-n + 3) {
                                margin-bottom: 28px;
                            }

                            p {
                                padding-right: 4px;
                                margin-top: 8px;
                                font-size: 18px;
                                color: #fff;
                                line-height: 18px;
                            }
                        }
                    }
                }

                .transfer-list {
                    margin-bottom: 20px;

                    .list-title {
                        margin-bottom: 12px;
                        font-size: 16px;
                        color: #fff;
                        line-height: 16px;

                        span {
                            color: #E15757;
                        }

                        img {
                            width: 14px;
                            height: 14px;
                            margin: 1px 0 0 4px;
                            cursor: pointer;
                        }
                    }

                    .list-input {
                        padding: 16px;
                        border-radius: 8px;
                        border: 1px solid #393939;
                        box-sizing: border-box;

                        input {
                            width: 100%;
                            height: 18px;
                            font-size: 14px;
                            color: #fff;

                            &::placeholder {
                                color: #5F5F5F;
                            }
                        }
                    }
                }

                .transfer-tips {
                    margin-bottom: 12px;

                    .tips-name {
                        flex: 1;
                        font-size: 16px;
                        color: rgba(255, 255, 255, 0.6);
                        line-height: 16px;

                        img {
                            width: 14px;
                            height: 14px;
                            margin: 1px 0 0 4px;
                            cursor: pointer;
                        }
                    }

                    .tips-info {
                        flex: 1;
                        font-size: 16px;
                        color: #fff;
                        line-height: 16px;
                        text-align: right;
                    }
                }

                .transfer-check {
                    padding: 20px 0 24px;
                    cursor: pointer;

                    img {
                        flex: 0 0 16px;
                        width: 16px;
                        height: 16px;
                        margin-right: 6px;

                    }

                    div {
                        font-size: 13px;
                        color: #fff;
                        line-height: 16px;
                    }
                }

                .btn-all {
                    justify-content: space-between;

                    div {
                        flex: 0 0 248px;
                        width: 248px;
                        height: 52px;
                        background: url('../../public/images/new/btnbg37.png') center no-repeat;
                        background-size: 100% 100%;
                        font-size: 16px;
                        color: #fff;
                        cursor: pointer;

                        &:last-child {
                            background: url('../../public/images/new/btnbg38.png') center no-repeat;
                            background-size: 100% 100%;
                            color: #000;
                        }
                    }
                }
            }

            .wrappar-rules {
                width: 505px;
                height: fit-content;
                margin-top: 56px;
                padding: 32px 40px 40px;
                border-radius: 12px;
                background: #242424;

                .rules-title {
                    font-size: 20px;
                    color: #fff;
                    line-height: 20px;
                    margin-bottom: 24px;
                }

                .rules-list {
                    div {
                        margin-bottom: 12px;
                        font-size: 14px;
                        color: #A8A8A8;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

.modal-con {
    position: relative;
    padding: 32px;
    width: 480px;

    .close {
        position: absolute;
        top: 32px;
        right: 32px;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }

    .modal-title {
        margin-bottom: 32px;
        font-size: 20px;
        color: #ffffff;
    }

    .modal-desc {
        font-size: 16px;
        color: rgba(255, 255, 255, 0.8);
    }

    .modal-input {
        margin: 20px 0 16px;
    }

    .modal-tips {
        font-size: 14px;
        color: #eb4343;
    }

    .modal-btncon {
        margin-top: 24px;
        height: 50px;
        background: url("../../public/images/new/btnbg16.png") center no-repeat;
        background-size: 100% 100%;
        font-size: 16px;
        color: #000000;
        cursor: pointer;
    }

    .forgottitle {
        margin-top: 24px;
        color: #c9fa5b;
        font-size: 16px;
        cursor: pointer;
        text-align: right;
    }

    .modal-btn {
        margin-top: 32px;
        justify-content: space-between;

        div {
            flex: 0 0 197px;
            height: 50px;
            background: url("../../public/images/new/btnbg14.png") center no-repeat;
            background-size: 100% 100%;
            font-size: 18px;
            color: #ffffff;
            cursor: pointer;

            &:last-child {
                background: url("../../public/images/new/btnbg15.png") center no-repeat;
                background-size: 100% 100%;
                color: #000000;
            }
        }
    }
}

@media (max-width: 1200px) {
    .transfer {
        padding: 88px 0 60px;

        .transfer-con {
            width: calc(100vw - 8px);

            .transfer-wrappar {
                padding: 16px;
                flex-wrap: wrap;

                .wrappar-left {
                    flex: 0 0 100%;
                    width: 100%;
                }

                .wrappar-rules {
                    flex: 0 0 100%;
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .transfer {
        padding: 56px 0;

        .transfer-con {
            width: calc(100vw - 8px);

            .transfer-tbg {
                display: none;
            }

            .transfer-wrappar {
                padding: 0;
                margin-top: 0;
                flex-wrap: wrap;
                background: none;

                .wrappar-left {
                    flex: 0 0 100%;
                    width: 100%;
                    padding: 16px;
                    border-radius: 8px;
                    background: #181818;

                    .left-name {
                        font-size: 18px;
                        margin-bottom: 23px;
                    }

                    .planet {
                        flex-wrap: wrap;
                        padding-bottom: 20px;

                        .img1 {
                            margin: 0 auto;
                        }

                        .planet-info {
                            flex: 0 0 100%;
                            width: 100%;
                            margin-left: 0;
                            padding: 20px 16px;
                            border-radius: 6px;
                            background: #242424;

                            .info {
                                font-size: 12px;
                                line-height: 12px;

                                &:nth-child(-n + 3) {
                                    margin-bottom: 16px;
                                }

                                p {
                                    font-size: 14px;
                                    line-height: 14px;
                                }
                            }
                        }
                    }

                    .transfer-list {
                        margin-bottom: 16px;

                        .list-title {
                            margin-bottom: 8px;
                            font-size: 14px;
                            line-height: 14px;
                        }

                        .list-input {
                            padding: 0 12px;

                            input {
                                height: 44px;
                                font-size: 14px;
                            }
                        }
                    }

                    .transfer-tips {
                        margin-bottom: 14px;

                        .tips-name,
                        .tips-info {
                            font-size: 13px;
                            line-height: 13px;

                            img {
                                width: 12px;
                                height: 12px;

                            }
                        }

                    }

                    .transfer-check {
                        padding: 13px 0 18px;

                        img {
                            flex: 0 0 13px;
                            width: 13px;
                            height: 13px;
                        }

                        div {
                            line-height: 13px;
                        }
                    }

                    .btn-all {
                        div {
                            flex: 0 0 48%;
                            height: 52px;
                            font-size: 16px;
                            background: url('../../public/images/new/btnbg39.png') center no-repeat;
                            background-size: 100% 100%;

                            &:last-child {
                                background: url('../../public/images/new/btnbg40.png') center no-repeat;
                                background-size: 100% 100%;
                            }
                        }
                    }
                }

                .wrappar-rules {
                    flex: 0 0 100%;
                    width: 100%;
                    margin-top: 16px;
                    padding: 16px;
                    border-radius: 8px;

                    .rules-title {
                        font-size: 18px;
                        line-height: 18px;
                        margin-bottom: 16px;
                    }

                    .rules-list {
                        div {
                            font-size: 13px;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }

    .modal-con {
        width: 100% !important;
        padding: 32px 16px;

        .modal-title {
            padding-right: 40px;
        }

        .close {
            right: 16px;
        }

        .modal-btn {
            div {
                flex: 0 0 49%;
                height: 44px;
            }
        }
    }
}</style>